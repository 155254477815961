/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file
import RIcon from './assets/images/R_logo.png';
import emoji from "react-easy-emoji";
import powerBI from './assets/images/powerBi.png';
import pyTorch from './assets/images/pytorch.png';
import tensorFlow from './assets/images/tensorflow.png';
import keras from './assets/images/keras.png';
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Barsha Pokharel",
  title: "Hello World! I'm Barsha",
  subTitle: emoji(
    `Hello there! I'm a data engineer with a passion for building data pipelines, visualizing data, and making sense of the digital chaos. When I'm not wrangling data or sipping coffee, you can find me daydreaming about optimized queries and data dashboards. I'm currently on the hunt for a new adventure, where I can bring my skills and humor to a team that's ready to change the world (or at least make it a more data-driven place). Let's connect and see if we can make some data magic happen!`
  ),
  resumeLink:
    "https://drive.google.com/file/d/1jqyStOjLHE8wHBUi74fdP-jMx__RiOK5/view", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links
const socialMediaLinks = {
  github: "https://github.com/maverickbarsha",
  linkedin: "https://www.linkedin.com/in/barsha-pokharel",
  gmail: "barshapokharel12@gmail.com",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "DATA ENGINEER WHO LOVES EVERYTHING ABOUT DATA",
  skills: [
    emoji(
      "⚡Design and develop scalable data pipelines for high-volume data ingetstion, processing and analytics"
    ),
    emoji("⚡  Optimize data processing and querying using distributed computing technologies like Hadoop and Spark"),
    emoji(
      "⚡   Implement data governance and quality control measures to ensure data accuracy and integrity"
    ),
    emoji(
      "⚡  Develop ETL processes for seamless data migration and integration"
    ),
    emoji(
      "⚡   Design and implement data visualization dashboards for insights and reporting"
    ),
    emoji(
      "⚡  Work with large-scale datasets to perform data analysis and modeling"
    ),
    emoji(
      "⚡  Implement machine learning models and algorithms for predictive analytics"
    ),
    emoji(
      "⚡ Implement seamless data migration and management strategies"
    ),
    emoji(
      "⚡ Ensure data security and compliance with industry standards and regulations"
    ),

  ],


  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "sql",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"

    },
    {
      skillName: "Power BI",
      icon: powerBI
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "PyTorch",
      icon: pyTorch
    },
    {
      skillName: "keras",
      icon: keras
    },
    {
      skillName: "TensorFlow",
      icon: tensorFlow
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "R",
      icon: RIcon
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "La Trobe University",
      logo: require("./assets/images/la_trobe.jpg"),
      subHeaders: ["Masters of Data Science"],
      duration: "February 2022 - November 2023",
      desc: "Melbourne, Australia",
    },
    {
      schoolName: "Pokhara University",
      logo: require("./assets/images/pu.jpg"),
      subHeaders: ["Bachelor of Engineering in Computer Engineering"],
      duration: "September 2015 - September 2019",
      desc: "Kathmandu, Nepal",
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Data Visualization", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Data Modeling and Prediction",
      progressPercentage: "60%"
    },
    {
      Stack: "Programming",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Management Consultant",
      company: "SPICAE",
      companylogo: require("./assets/images/spicae.png"),
      logoScale: 0.8,
      date: "Nov 2023 – Present",
      desc: "Teamed up with a high velocity consulting team alongside other engineers to deliver scalable software solutions.",
      descBullets: [
        "Streamlined data analysis by translating complex SQL queries into DAX, enhancing the computation of critical KPIs across various time periods and workgroups.",
        "Employed Power BI to create dynamic, hierarchical visualizations of KPIs from datasets aggregated across multiple sources, streamlining the reporting processes and enhancing stakeholder engagement by 30%."
      ]
    },
    {
      role: "Data Analyst Intern",
      company: "Annecto",
      companylogo: require("./assets/images/annecto.png"),
      logoScale: 0.5,
      date: "Mar 2023 – Jun 2023",
      desc: "Collaborated with other interns and full-time engineers to add new features and develop PoC for future developments.",
      descBullets: [
        "Implemented real-time data visualization for immediate monitoring and strategic adjustments across multiple marketing channels (Facebook, Instagram, LinkedIn, Google Analytics, Twitter).",
        "Enhanced dashboard with advanced filtering capabilities for internal and external stakeholders and enabled effortless sorting of data by target audiences, marketing channels, and demographic information."
      ]
    },
    {
      role: "Django Developer",
      company: "Nepal Innovation Centre",
      companylogo: require("./assets/images/nic.png"),
      date: "Dec 2020 – Jan 2022",
      desc: "Contributed to a high-performing team of engineers, leveraging Agile and Scrum methodologies to develop a cutting-edge virtual classroom platform.",
      descBullets: [
        "Built a virtual classroom platform with the ability to run and support simultaneous mode of interactions like discussion forums, individual chats, uploading videos, social response, and expert verification across multiple courses."
      ]
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  projects: [
    {
      image: require("./assets/images/news_classifier.webp"),
      projectName: "News classification using PyTorch",
      projectDesc: "Developed PyTorchNewsClassifier utilizing PyTorch framework and created a neural network that categorizes news articles into four distinct categories: Sports, World, Business, and Science Tech with 97% accuracy.",
      footerLink: []
    },
    {
      image: require("./assets/images/university.webp"),
      projectName: "American University Attendance Risk Prediction",
      projectDesc: "Developed a neural network classifier (NN) to identify students at high risk of low attendance based on academic performance, achieving 79% accuracy and enabling the university to support them through targeted interventions.",
      footerLink: []
    },
    {
      image: require("./assets/images/chatex.jpg"),
      projectName: "ChaTex",
      projectDesc: "Created an Android chat application that extends existing chat applications' features by letting users send mathematical equations and expressions in their natural form along with text messages. ChaTeX uses LaTeX for the typesetting of math equations.",
      footerLink: []
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Awards"),
  achievementsCards: [
    {
      title: "Srijana The Innovation",
      subtitles: ["First Prize in Software Competition", "organized by Advanced College of Engineering and Management", "Lalitpur, Nepal"],
      footerLink: []
    },
    {
      title: "Yatra 1.0",
      subtitles: ["First Prize in Software Competition", "organized by National College of Engineering", "Lalitpur, Nepal"],
      imageAlt: "Yatra 1.0",
      footerLink: []
    },
    {
      title: "Programming Competition",
      subtitles: ["First Prize in NOSKode(Python Programming)", "organized by Nepal Open Source Klub", "Lalitpur, Nepal"],
      imageAlt: "Programming Competition",
      footerLink: []
    }
  ],
  display: true // Set false to hide this section, defaults to true
};


const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Want to discuss an interesting opportunity or just want to say hi? My Inbox is open for all.",
  email_address: "barshapokharel12@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  contactInfo,
  twitterDetails,
  isHireable
};
